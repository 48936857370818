import { Action } from '../api/src/models/Action';

export default {
  data() {
    return {
      lineChartOptions: {
        scales: {
          y: {
            display: false,
            offset: true,
            ticks: {
              beginAtZero: true,
            },
            grid: {
              display: false,
            },
          },
          x: {
            display: false,
            offset: true,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: false,
        maintainAspectRatio: false,
        animation: {
          duration: 0, // general animation time
        },
      },

      doughnutChartOptions: {
        scales: {
          y: {
            display: false,
            ticks: {
              beginAtZero: true
            },
            grid: {
              display: false,
            },
          },
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: false,
        maintainAspectRatio: false,
        animation: {
          duration: 0, // general animation time
        },
      },
    };
  },
  computed: {
    valuesOverTimeForChart() {
      const chartData = {
        labels: [],
        datasets: [
          {
            borderColor: '#ced4da',
            label: 'Earned',
            fill: false,
            lineTension: 0.2,
            pointRadius: 0,
            pointHitRadius: 10,
            data: [],
          },
          {
            borderColor: 'rgba(200, 200, 200, 0.5)',
            borderDash: [5, 5],
            label: 'Projected',
            fill: false,
            lineTension: 0.2,
            pointRadius: 0,
            pointHitRadius: 10,
            data: [],
          },
        ],
      };

      this.$store.getters['actionsUser/valueTotalsRecent'].forEach((v, i) => {
        const di = this.$store.state.recentDaysCount - i;
        const d = this.$store.getters.virtualDay.subtract(di, 'days');

        chartData.labels.push(di === 1 ? 'Yesterday' : d.fromNow());
        chartData.datasets[0].data.push(v);
        chartData.datasets[1].data.push(di === 1 ? v : null);
      });

      chartData.labels.push('Today');
      chartData.datasets[0].data.push(this.$store.getters['actionsUser/valueTotalVirtualDay']);
      chartData.datasets[1].data.push(this.$store.getters['actionsPlan/valueOfItems']);

      return chartData;
    },
    valuesOverTimeForChartPlus() {
      const chartData = this.valuesOverTimeForChart;

      let di;

      for (di = 1; di <= this.$store.state.lookaheadDaysCount; di++) {
        let val = 0;
        const d = this.$store.getters.virtualDay.add(di, 'days');

        const items = this.$store.getters['actionsUser/itemsExpiringOnVirtualDate'](d)
          .filter((i) => {
            const isRepeating = Action.isFrequencyRepeating(i.frequency);
            const includedInActionsPlan = !isRepeating && this.$store.getters['actionsPlan/itemIds'].includes(i.id);
            return !includedInActionsPlan && (!isRepeating || i.importance === Action.EffortImportance.ImportanceMaxValue);
          });

        items.forEach((i) => {
          val = val + this.$store.getters.getValuePotentialForItem(i);
        });

        chartData.labels.push(d.fromNow());
        chartData.datasets[1].data.push(val);
      }

      return chartData;
    },
    recentInterests() {
      return this.$store.getters.interestsWithRecentActionsCompleted;
    },
    earnedValueByInterest() {
      const chartData = {
        labels: [],
        datasets: [{
          label: 'Earned Value By Interest',
          backgroundColor: [],
          data: [],
        }],
      };

      if (this.recentInterests) {
        const totalValue = this.recentInterests.reduce((accum, interest) => {
          return accum + interest.value;
        }, 0);

        this.recentInterests.forEach((interest) => {
          const percent = Math.floor((interest.value / totalValue) * 100);
          chartData.labels.push(`${interest.name} ${percent}%`);
          chartData.datasets[0].data.push(interest.value);
          chartData.datasets[0].backgroundColor.push(this.$store.getters['interests/getColorForItem'](interest));
        });
      }

      return chartData;
    },
    potentialValueByInterest() {
      const chartData = {
        labels: [],
        datasets: [{
          label: 'Potential Value By Interest',
          backgroundColor: [],
          data: [],
        }],
      };

      if (this.$store.state.interests.all && this.$store.state.interests.all.length > 0) {
        const interests = this.$store.state.interests.all.map(i => ({ ...i, value: this.$store.getters['actionsUser/getValuePotentialForInterest'](i.id) }));
        const totalValue = interests.reduce((accum, i) => (accum + i.value), 0);

        interests.forEach((i) => {
          const percent = Math.floor((i.value / totalValue) * 100);
          chartData.labels.push(`${i.name} ${percent}%`);
          chartData.datasets[0].data.push(i.value);
          chartData.datasets[0].backgroundColor.push(this.$store.getters['interests/getColorForItem'](i));
        });
      }

      return chartData;
    },
  },
};
