
import { Line } from 'vue-chartjs';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);

export default {
  name: 'ChartLine',
  components: {
    // because <line> is reserved
    LineComp: Line,
  },
  props: {
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
    chartData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
